
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import optionsSelector from '@/components/optionsSelector.vue';
import * as OM from '@/Model';

@Options({})
export default class EditModal extends Vue {

    @Prop({
        default: () => new OM.ServiceSoldVM()
    }) model: OM.ServiceSoldVM;

    @Prop() garmentsList: OM.TextIdentifier[];

    localModel: OM.ServiceSoldVM = new OM.ServiceSoldVM();
    garmentsListLocal: OM.TextIdentifier[];

    @Prop() callback: any;

    created() {
        this.localModel = this.model;
        this.garmentsListLocal = this.garmentsList;
        console.log(this.garmentsList)
    }

    get canSave(){
        return this.localModel.garmentsCalculation && this.localModel.monthDuration  && this.localModel.price && this.localModel.userLicense;
    }

    save() {
        if(!this.canSave)
            return;

        this.callback(this.localModel);
    }
    
}
