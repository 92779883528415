
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, GarmentClient, ServiceSoldConfigurationClient } from '@/services/Services';
import EditModal from '../modals/editModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';


@Options({
    components: {}
})
export default class ServicesSold extends Vue {

    items: OM.ServiceSoldVM[] = [];
    garments: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        Promise.all([
            GarmentClient.getAllConfigurations(),
            ServiceSoldConfigurationClient.getAllConfigurations()
        ]).then(xs =>{
            this.garments = xs[0];
            this.items = xs[1];
            this.loaded = true;
        })
    }

    editConfiguration(item: OM.ServiceSoldVM) {
        if(!item)
            item = new OM.ServiceSoldVM();

        this.$opModal.show(EditModal, {
            model: item,
            garmentsList: this.garments,
            callback: (updatedModel: OM.ServiceSoldVM) => {
                ServiceSoldConfigurationClient.editConfiguration(updatedModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }



    removeServicesSold(item: OM.ServiceSoldVM){
        if(!confirm(this.$localizationService.getLocalizedValue("Confermi la rimozione?", "Confermi la rimozione?")))
        return
        ServiceSoldConfigurationClient.deleteConfiguration(item.identifier)
        .then(x => {
            this.init()
        })
    }
}
